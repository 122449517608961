@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import 'variables.less';
@import 'fonts.less';
@import 'typographies.less';
@import 'overrides.less';
@import 'primary-colors.less';
@import 'shading-colors.less';
@import 'sunset-colors.less';


html, body {
  height: 100%; 
}

body {
  font-family: AcuminPro, sans-serif !important;
  font-size: 14px;
  line-height: 20px;
}


.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.divider {
  border-bottom: solid 0.5px var(--grey);
  margin: 20px 0;
}
.thick-divider {
  border-bottom: solid 2px #9BA9BA;
  margin: 20px 0;
}

.width-100 {
  width: 100%;
}

.centered {
  padding: 0 104px;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 5px 24px;
  }
}
@primary-color: #f06922;