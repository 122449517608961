@font-face {
  font-family: AcuminPro;
  src: url("./fonts/Acumin-RPro.otf");
  font-display: swap;
}

@font-face {
  font-family: AcuminPro;
  font-weight: bold;
  src: url("./fonts/Acumin-BdPro.otf") format("opentype");
  font-display: swap;
}