.ant-btn {
  &.ant-btn-default,
  &.ant-btn-primary,
  &.ant-btn-text,
  &.ant-btn-background-ghost,
  &.ant-btn-ghost {
    font-weight: bold;
    height: 48px;
    font-size: 14px;
    border-radius: 4px;
  }
  &.ant-btn-text,
  &.ant-btn-background-ghost,
  &.ant-btn-ghost {
    color: #f06922;
  }
  &.ant-btn-primary[disabled],
  &.ant-btn-primary[disabled]:hover,
  &.ant-btn-primary[disabled]:focus,
  &.ant-btn-primary[disabled]:active,
  &.ant-btn-text[disabled],
  &.ant-btn-text[disabled]:hover,
  &.ant-btn-text[disabled]:focus,
  &.ant-btn-text[disabled]:active {
    background: var(--coal-300) !important;
    color: var(--coal-500) !important;
  }
  &.ant-btn-default {
    border: 0;
    background: rgba(0, 0, 0, 0.1);
  }
  &.ant-btn-background-ghost,
  &.ant-btn-ghost {
    border: 1px solid var(--manila-bay-orange);
  }
}

.ant-input,
.ant-picker,
.ant-picker-input {
  border: 0;
  background: #f6f8fa;
  height: 40px;
  border: 1px solid #d3dae1;
  border-radius: 4px !important;
  &:focus,
  &:hover {
    background: #f6f8fa !important;
    box-shadow: none !important;
    border: 1px solid #d3dae1;
  }

  &.ant-input[disabled],
  &.ant-input[disabled]:hover,
  &.ant-input[disabled]:active {
    background: #dbe0e7 !important;
    color: var(--coal-500);
  }

  .ant-picker-input {
    border-top: 1px solid #d3dae1;
    border-bottom: 1px solid 1px solid #d3dae1;
    border-left: none !important;
    border-right: none !important;
  }
}

.ant-input-password,
.ant-input-search,
.ant-input-affix-wrapper,
.ant-picker,
.ant-picker-focused {
  background: #f6f8fa;
  padding: 0 8px 0 8px;
  border: 1px solid #d3dae1;
  border-radius: 4px !important;
  &:focus-within {
    background: #f6f8fa !important;
    border: 1px solid #d3dae1;
    box-shadow: none !important;
  }
}

.ant-input-search,
.ant-input-affix-wrapper {
  border: 1px solid #d3dae1;
  border-radius: 4px !important;
  &:focus,
  &:hover {
    background: #f6f8fa !important;
    box-shadow: none !important;
    border: 1px solid #d3dae1;
  }
  .ant-input-suffix {
    border: 0;
    svg {
      color: #f06922;
    }
  }
}

.ant-form-item {
  margin-bottom: 20px;
  .form-item-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}
.ant-form-item-label {
  padding: 0 0 6px !important;
  font-weight: bold;
  height: 30px;
}

.ant-message {
  top: 0;
  .ant-message-notice.warning-wide {
    background-color: #f06922;
    height: 40px;
    .ant-message-notice-content {
      background: none;
      padding: 0;
      border: 0;
      box-shadow: none;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
    .anticon {
      display: none;
    }
  }
  .ant-message-notice.error-wide {
    background-color: #c82127;
    height: 40px;
    .ant-message-notice-content {
      background: none;
      padding: 0;
      border: 0;
      box-shadow: none;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
    .anticon {
      display: none;
    }
  }
  .ant-message-notice.success-wide {
    background-color: #268e6c;
    height: 40px;
    .ant-message-notice-content {
      background: none;
      padding: 0;
      border: 0;
      box-shadow: none;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
    .anticon {
      display: none;
    }
  }
}

.ant-select {
  height: 40px;
  background: #f6f8fa;
  border-radius: 4px !important;
  .ant-select-selector {
    height: 40px !important;
    background: #f6f8fa !important;
    border: 1px solid #d3dae1 !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    input {
      height: 40px !important;
    }
    .ant-select-selection-item {
      line-height: 40px !important;
    }
  }
  .ant-select-selection-placeholder {
    line-height: 40px !important;
  }
}

.ant-modal-confirm {
  svg {
    display: none;
  }
  .ant-modal-content {
    width: 450px;
    border-radius: 16px;
  }
  .ant-modal-body {
    padding: 32px;
    .ant-modal-confirm-title {
      font-size: 28px;
      font-weight: 700;
      line-height: 28px;
    }
    .ant-modal-confirm-content {
      margin-left: 0 !important;
    }
  }
  .ant-modal-confirm-confirm {
    width: 400px;
    .ant-modal-content {
      border-radius: 16px !important;
    }
  }
  .ant-modal-confirm-btns {
    width: 100%;
    .ant-btn {
      height: 40px !important;    
      width: 48%;
      border: 1px solid var(--manila-bay-orange);
      color: var(--manila-bay-orange);
    }
    .ant-btn-primary {
      color: var(--white);
    }
  }
}

.ant-alert {
  border-radius: 4px;
  &.ant-alert-warning {
    background: #f9db18;
    color: var(--manila-bay-black);
    svg {
      color: var(--manila-bay-black);
    }
  }
  &.ant-alert-error {
    background: var(--manila-bay-red);
    color: var(--white);
    svg {
      color: var(--white);
    }
  }
  &.ant-alert-success {
    background: var(--forest-green);
    color: var(--white);
    svg {
      color: var(--white);
    }
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    background: var(--coal-600);
    color: var(--white);
  }
}

.anticon-question-circle {
  color: var(--manila-bay-orange);
}

.ant-popover
  > &.ant-popover-content
  > &.ant-popover-inner
  > &.ant-popover-inner-content
  > &.ant-popover-buttons {
  button {
    height: 30px !important;
    min-width: 75px;
  }
  .ant-btn {
    border: none !important;
    font-weight: bold;
    color: var(--coal-700);
  }
  .ant-btn-primary {
    color: var(--white);
  }
}

.ant-btn-icon-only {
  border: none !important;
}

.ant-select-multiple > .ant-select-selector > .ant-select-selection-item {
  line-height: normal !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #1A202C;
  font-weight: bold;
  border-radius: 4px;
  color: var(--white);
  >.ant-select-selection-item-remove {
    color: var(--white) !important;
  }
}

.cookie-notif {
  width: 603px;
  height: 268px;
  background: #FFFFFF;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.07), 0px 0px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 0px 10.0172px rgba(0, 0, 0, 0.035), 0px 0px 3.62304px rgba(0, 0, 0, 0.0243888);
  border-radius: 16px;
  padding: 33px;
  background-image: url(../svg/cookie-notif-bg.svg);
  background-repeat: no-repeat;
  background-position: right;
  .ant-notification-notice-icon {
    position: relative;
    width: 24px;
    height: 28px;
  }
  .ant-notification-notice-message {
    margin: 10px 0 8px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
  .ant-notification-notice-description {
    margin: 10px 0 8px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  .ant-notification-notice-close {
    display: none !important;
  }
  @media (max-width: 768px) {
    height: auto;
  }
}