h1 {
  font-size: 40px;
  font-weight: bold;
  line-height: 48px;

  @media (min-width: 768px) {
    font-size: 38px;
  }
}

h2 {
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
}

h3 {
  font-size: 28px;
  font-weight: bold;
  line-height: 40px;
}

h4 {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}

h5 {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}

h6 {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.text-subtitle {
  font-size: 16px;
  font-weight: bold;
}

a, button {
  font-size: 14px;
}

a {
  font-weight: bold;;
}

.label-error {
  float: right;
  color: red;
  font-size: 12px;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-sm {
  font-size: 12px;
}

.help-text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.text-label {
  font-size: 12px;
  color: var(--coal-500);
}

.premium {
  color: #268E6C;
}

.standard {
  color: #F06922;
}

.basic {
  color: #FAAB19;
}

.custom {
  color: #778294;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}