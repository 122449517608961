:root {
  --manila-bay-black-80: rgba(0, 0, 0, 0.8);
  --neon-red-25: rgba(255, 0, 52, 0.25);
  --white-80: rgba(255, 255, 255, 0.8);
  --grey: rgba(0, 0, 0, 0.2);
  --manila-bay-black: #000000;
  --manila-bay-red: #c82127;
  --manila-bay-orange: #f06922;
  --manila-bay-gold: #faab19;
  --manila-bay-yellow: #f9db18;
  --forest-green: #268e6c;
  --white: #ffffff;
}
